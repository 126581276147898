.navbar {
  background: rgba(18,18,24,.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .link {
    color: #8a92aa !important;
    font-size: 18px;

    &:hover {
      color: white !important;
    }
  }

}
  .pdf {
    color: black !important;

    &:hover {
      background: -webkit-linear-gradient(#f81d00,#f60);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

.link {
  color: #8a92aa !important;
  font-size: 18px;

  &:hover {
    color: white !important;
  }
}
